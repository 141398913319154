// extracted by mini-css-extract-plugin
export var container = "index-module--container--fe9U7";
export var header = "index-module--header--tevmp";
export var img_container = "index-module--img_container--FUPiC";
export var left_container = "index-module--left_container--fqkkZ";
export var user_info = "index-module--user_info--s3GMe";
export var user_name = "index-module--user_name--fd7-4";
export var profile_picture = "index-module--profile_picture--Q8kzy";
export var date = "index-module--date--bglnK";
export var stars_container = "index-module--stars_container--i9xRZ";
export var star = "index-module--star--IBPv1";
export var card_footer = "index-module--card_footer--alHbz";
export var modalContainer = "index-module--modalContainer--5upp2";
export var readMore = "index-module--readMore--+a2ue";