import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import ReviewCard from "../../components/ui/base/ReviewCard"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { useTranslation } from "react-i18next"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"

const ReviewsPage = ({ data }) => {
  const { t, i18n: l } = useTranslation();

  const headerPicture = data?.allFile.edges[0].node.childImageSharp.fluid;
  const reviews = data?.allStrapiReview.edges;
  return (
    <>

      <GatsbySeo
        htmlAttributes={{ lang : l.language }}
        title={t("templates.reviews.metaTitle")}
        description={t("templates.reviews.metaDescription", {count: reviews.length})}
        language={l.language}
      />
      <Header
        img={headerPicture}
        theme="dark"
        size={"compact"}
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {t("templates.reviews.title")}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading2">
                  {t("templates.reviews.description", {count: reviews.length})}
                </Typography>
                <Breadcrumbs elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Recensioni",
                    path: "/recensioni/",
                  }
                ]} />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>
      <div style={{ backgroundColor: "#fff" }}>
        <Container fluid="xl" className="pt-8 pb-8">
          <div className="main-title">
            <Typography variant="heading2">
            </Typography>
            <Typography variant="heading3">
            </Typography>
          </div>

          <Row>
            {reviews.map(review => {
              return (
                <Col lg={4} md={6} className="zoomIn mb-2" key={review.node.id}>
                  <ReviewCard  review={review.node}/>
                </Col>
              )
            })
            }
          </Row>


        </Container>
      </div>



    </>
  )
}

export default ReviewsPage


export const query = graphql`
  query reviewsQuery {
   allFile(filter: {name: {eq: "viaggi-fotografici-toscana"}}) {
            edges {
              node {
                ...ImageFragment
              }
            }
        }
    allStrapiReview(sort: {fields: published_at, order: DESC}) {
    edges {
      node {
        title
        tour
        user {
          firstName
          profilePicture {
            localFile {
              absolutePath
            }
          }
        }
        rating
        description
        created_at
      }
    }
  }
}
`


