import classnames from "classnames"
import React, { useState } from "react"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import Modal from "react-modal"
import Button from "../Button"
import { createMarkup } from "../../../../util/tour"

const ReviewCard = ({ review, tour }) => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)
  const userPicture = data?.allFile.edges[0].node
  const [modalOpen, setModalOpen] = useState(false)
  const modalCloseTimeout = 300
  const closeModal = () => {
    setModalOpen(false)
  }
  const openModal = () => {
    setModalOpen(true)
  }

  const needsReadMore = () => {
    return review.description.length > 160
  }
  const renderStars = () => {
    const stars = []
    for (let i = 0; i !== 5; i++) {
      stars.push(1)
    }
    return stars
  }
  const stars = renderStars()

  const longDescription = review.description.replace(". ", ".<br><br>")

  return (
    <>
      <div
        onClick={() => {
          openModal()
        }}
        onKeyDown={() => {}}
        className={classnames([styles.container])}
        key={review.title}
      >
        <div className={classnames([styles.header])}>
          <div className={classnames([styles.left_container])}>
            <div className={classnames([styles.profile_picture])}>
              <GatsbyImage
                image={userPicture?.childImageSharp.gatsbyImageData}
                alt={review.user.firstName}
              />
            </div>

            <div className={classnames([styles.user_info])}>
              <Typography
                variant="heading3"
                className={classnames([styles.user_name])}
              >
                {review.user.firstName}
              </Typography>
              <Typography variant="body" className={classnames([styles.date])}>
                {moment(review.created_at).format("D MMM YYYY")}
              </Typography>
            </div>
          </div>

          <div className={classnames([styles.stars_container])}>
            {stars.map((star, index) => {
              return (
                <span key={index} className={classnames([styles.star])}>
                  ⭐️
                </span>
              )
            })}
          </div>
        </div>
        <div className={classnames([styles.card_footer])}>
          {review.description}
        </div>
        {needsReadMore() && (
          <div className={classnames([styles.readMore])}>
            {t("ui.components.base.ReviewCard.readMore")}
          </div>
        )}
      </div>
      <Modal
        className={classnames([styles.modal])}
        isOpen={modalOpen}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.58)",
            zIndex: 200,
          },
        }}
        onRequestClose={closeModal}
        contentLabel="Modal"
        closeTimeoutMS={modalCloseTimeout}
      >
        <Button
          className="modalCloseButton"
          onClick={() => {
            closeModal()
          }}
        >
          Close <i className="icon-cancel-1" />{" "}
        </Button>
        <div className={classnames([styles.modalContainer])}>
          <div className={classnames([styles.header])}>
            <div className={classnames([styles.left_container])}>
              <div className={classnames([styles.profile_picture])}>
                <GatsbyImage
                  image={userPicture?.childImageSharp.gatsbyImageData}
                  alt={review.user.firstName}
                />
              </div>

              <div className={classnames([styles.user_info])}>
                <Typography
                  variant="heading3"
                  className={classnames([styles.user_name])}
                >
                  {review.user.firstName}
                </Typography>
                <Typography
                  variant="body"
                  className={classnames([styles.date])}
                >
                  {moment(review.created_at).format("D MMM YYYY")}
                </Typography>
              </div>
            </div>

            <div className={classnames([styles.stars_container])}>
              {stars.map((star, index) => {
                return (
                  <span key={index} className={classnames([styles.star])}>
                    ⭐️
                  </span>
                )
              })}
            </div>
          </div>
          <div
            className={classnames([styles.card_footer])}
            dangerouslySetInnerHTML={createMarkup(longDescription)}
          />
        </div>
      </Modal>
    </>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "user_avatar" } }) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
  }
`

export default ReviewCard
